import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { FormGroup, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepLate1Image {
    file(relativePath: { eq: "stepLate1.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  checkbox: {
    marginLeft: "15px",
  },
})

const StepLateCKD = () => {
  const { k, setK, kUnit, setKUnit, be, setBe, beUnit, setBeUnit } = useContext(
    HealthCheckContext
  )

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Advanced Stage CKD Care - Lab</Typography>
      <Typography paragraph variant="body2">
        Additional Lab Values that deserve attention in advanced stage kidney
        disease.
      </Typography>
      <FormGroup>
        <ValueInput
          wide
          label="Potassium (K)"
          value={k}
          setValue={setK}
          allUnits={units.k}
          unit={kUnit}
          setUnit={setKUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          negative
          label="Base Excess (BE)"
          value={be}
          setValue={setBe}
          allUnits={units.be}
          unit={beUnit}
          setUnit={setBeUnit}
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepLateCKD

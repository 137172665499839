import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { FormGroup, Typography, Slider } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepDialysisImage {
    file(relativePath: { eq: "stepDialysis.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  subheader: {
    marginBottom: "15px",
    marginTop: "20px",
  },
  slider: {
    width: "150px",
    margin: "0px 25px 15px 0px",
  },
})

const StepDialysis = () => {
  const {
    k,
    setK,
    kUnit,
    setKUnit,
    be,
    setBe,
    beUnit,
    setBeUnit,
    weightGain,
    setWeightGain,
    diaLen,
    setDiaLen,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Dialysis Care</Typography>
      <Typography paragraph variant="body2">
        Additional factors that matter when it comes to Quality of Care on
        Dialysis.
      </Typography>
      <Typography>Serum values (measured before dialysis sessions)</Typography>
      <FormGroup>
        <ValueInput
          wide
          label="Potassium (K)"
          value={k}
          setValue={setK}
          allUnits={units.k}
          unit={kUnit}
          setUnit={setKUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          negative
          label="Base Excess (BE)"
          value={be}
          setValue={setBe}
          allUnits={units.be}
          unit={beUnit}
          setUnit={setBeUnit}
        />
      </FormGroup>

      <Typography className={classes.subheader}>
        Length of Dialysis Session and average weight gain between two sessions
      </Typography>

      <FormGroup row>
        <Slider
          className={classes.slider}
          value={diaLen}
          onChange={(event, value) => setDiaLen(value)}
          step={0.5}
          marks
          min={3}
          max={10}
        />
        <Typography>
          Dialysis Length: <strong>{diaLen}</strong>
          {" H"}
        </Typography>
      </FormGroup>

      <FormGroup row>
        <Slider
          className={classes.slider}
          value={weightGain}
          onChange={(event, value) => setWeightGain(value)}
          step={0.1}
          marks
          min={0}
          max={5}
        />
        <Typography>
          {" "}
          Weight Gain: <strong>{weightGain}</strong>
          {" L"}
        </Typography>
      </FormGroup>
    </StepLayout>
  )
}

export default StepDialysis

import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import {
  FormGroup,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import NumberFormat from "react-number-format"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepHeartImage {
    file(relativePath: { eq: "stepHeart.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  formGroup: {
    margin: "15px 0px",
  },
  bpField: {
    maxWidth: "90px",
    margin: "0px 10px",
  },
})

const StepHeart = () => {
  const {
    bpSys,
    setBpSys,
    bpDia,
    setBpDia,
    ldl,
    setLdl,
    ldlUnit,
    setLdlUnit,
    trig,
    setTrig,
    trigUnit,
    setTrigUnit,
    bpMed,
    setBpMed,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Kidney-Heart Connection</Typography>
      <Typography paragraph variant="body2">
        Kidney and Heart Health are related. Check your Cardiovascular Status...
      </Typography>
      <Typography>Average Blood Pressure</Typography>
      <FormGroup row className={classes.formGroup}>
        <NumberFormat
          allowLeadingZeros={false}
          format="###"
          customInput={TextField}
          size="small"
          className={classes.bpField}
          id="SystolicBP"
          label="Systolic"
          value={bpSys}
          onChange={event => setBpSys(event.target.value)}
          variant="outlined"
        ></NumberFormat>
        <Typography variant="h4">/</Typography>
        <NumberFormat
          allowLeadingZeros={false}
          format="###"
          customInput={TextField}
          size="small"
          className={classes.bpField}
          id="DiastolicBP"
          label="Diastolic"
          value={bpDia}
          onChange={event => setBpDia(event.target.value)}
          variant="outlined"
        ></NumberFormat>
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={bpMed}
              onChange={event => {
                setBpMed(event.target.checked)
              }}
              name="bpmed"
            />
          }
          label="Regular Use of Antihypertensive Drugs"
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="LDL Cholesterol"
          value={ldl}
          setValue={setLdl}
          allUnits={units.ldl}
          unit={ldlUnit}
          setUnit={setLdlUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="Triglycerides"
          value={trig}
          setValue={setTrig}
          allUnits={units.triglycerides}
          unit={trigUnit}
          setUnit={setTrigUnit}
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepHeart

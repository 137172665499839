import React, {useContext} from 'react'
import { ScreenContext } from '../../../../../context/screenContext';
import { Grid } from '@material-ui/core';
import Image from "gatsby-image"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    image: {
        borderRadius: "10px",
      }, 
})

const StepLayout = ({children, imageFluid}) => {

    const screen = useContext(ScreenContext)

    const classes = useStyles()  

    if (screen.mobile) {
        return <>{children}</>
    } else {
        return (
            <Grid container spacing={5}>
                <Grid item md={5}>
                    <Image
                        fluid={imageFluid}
                        className={classes.image}
                    />
                </Grid>
                <Grid item md={7}>
                    {children}
                </Grid>
            </Grid>
        )
    }
}

export default StepLayout

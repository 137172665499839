import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getPBreakpoints = pUnit => {
  if (pUnit === "mg/dL") {
    return {
      low: 4.5,
      medium: 5.5,
      high: 6.2,
    }
  } else
    return {
      low: 1.45,
      medium: 1.8,
      high: 2,
    }
}

const getPResult = (p, pUnit, dialysis) => {
  if (p > 0) {
    if (p <= getPBreakpoints(pUnit).low) {
      return {
        text: `Serum Phosphorus ${p} ${pUnit}: Optimal`,
        code: 1,
        desc: "",
      }
    } else if ((p <= getPBreakpoints(pUnit).medium) & dialysis) {
      return {
        text: `Serum Phosphorus ${p} ${pUnit}: Slightly elevated, but still in acceptable range.`,
        code: 2,
        desc: `On Dialysis Phosphorus values up to ${
          getPBreakpoints(pUnit).medium
        } ${pUnit} are still ok. The challenge at dialysis is to get enough Protein and at the same time keep the Phosphorus Level low.`,
      }
    } else if ((p <= getPBreakpoints(pUnit).medium) & !dialysis) {
      return {
        text: `Serum Phosphorus ${p} ${pUnit}: Slightly elevated, try to bring it back into the normal range.`,
        code: 2,
        desc: `Keep Phosphorus level below ${
          getPBreakpoints(pUnit).low
        } ${pUnit}. At the same time keep an eye on your Serum Albumin to make sure you get enough Protein.`,
      }
    } else {
      return {
        text: `Serum Phosphorus ${p} ${pUnit}: To high.`,
        code: 3,
        desc: `Constantly elevated serum Phosphorus Levels damge the kidneys and are a major risk factor for Cardiovascular and Bone Disease. As a first step try to keep Serum Phosphorus below ${
          getPBreakpoints(pUnit).medium
        } ${pUnit}.`,
      }
    }
  } else {
    return {
      text: "Serum Phosphorus unknown",
      code: 0,
      desc:
        "When faced with Kidney Disease you should always keep an eye on your Serum Phosphorus level.",
    }
  }
}

const getCaBreakpoints = pUnit => {
  if (pUnit === "mg/dL") {
    return {
      normalLow: 8.5,
      normalMedium: 9.5,
      normalHigh: 10.5,
    }
  } else if (pUnit === "mEq/L") {
    return {
      normalLow: 4.3,
      normalMedium: 4.8,
      normalHigh: 5.3,
    }
  } else {
    return {
      normalLow: 2.1,
      normalMedium: 2.4,
      normalHigh: 2.7,
    }
  }
}

const getCaResult = (ca, caUnit, dialysis) => {
  if (ca > 0) {
    if (dialysis) {
      if (ca < getCaBreakpoints(caUnit).normalLow) {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Below normal.`,
          code: 2,
          desc:
            "Your Calcium level seems to be below normal. Talk with your doctor about the need for interventions (oral calcium carbonate, adjustment of dialysate calcium, oral active Vitamine D)",
        }
      } else if (ca < getCaBreakpoints(caUnit).normalMedium) {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Optimal.`,
          code: 1,
          desc: "",
        }
      } else if (ca < getCaBreakpoints(caUnit).normalHigh) {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Within the reference range, but slightly lower would be optimal.`,
          code: 2,
          desc: `The optimal Calcium Level for dialysis patients is more towards the lower end (< ${
            getCaBreakpoints(caUnit).normalMedium
          } ${caUnit}).`,
        }
      } else {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Elevated.`,
          code: 2,
          desc:
            "Discontinue Calcium Supplements if you take any and talk with your doctor how to lower your Calcium Level.",
        }
      }
    } else {
      if (ca < getCaBreakpoints(caUnit).normalLow) {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Below normal.`,
          code: 2,
          desc:
            "Your Calcium level seems to be below normal. Talk with your doctor about the need for interventions (oral calcium carbonate, oral active Vitamine D)",
        }
      } else if (ca < getCaBreakpoints(caUnit).normalHigh) {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Within the reference range.`,
          code: 1,
          desc: "",
        }
      } else {
        return {
          text: `Serum Calcium ${ca} ${caUnit}: Elevated.`,
          code: 2,
          desc:
            "Discontinue Calcium Supplements if you take any and talk with your doctor how to lower your Calcium Level.",
        }
      }
    }
  } else {
    return {
      text: "Serum Calcium unknown",
      code: 0,
      desc: "Check your Lab Report for Serum Calcium.",
    }
  }
}

const getPthBreakpoints = pUnit => {
  return {
    ckdLow: 70,
    ckdMedium: 110,
    diaLow: 150,
    diaMedium: 300,
  }
}

const getPthResult = (pth, pthUnit, dialysis, lateCKD) => {
  if (pth > 0) {
    if (dialysis) {
      if (pth < getPthBreakpoints().diaLow) {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Lower than Optimal.`,
          code: 2,
          desc: `On Dialysis optimal PTH range is from ${
            getPthBreakpoints().diaLow
          } ${pthUnit} to ${
            getPthBreakpoints().diaMedium
          } ${pthUnit}. If taking active Vitamine D the dose could be reduced. Talk with your doctor.`,
        }
      } else if (pth < getPthBreakpoints().diaMedium) {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Optimal.`,
          code: 1,
          desc: "",
        }
      } else {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Higher than Optimal.`,
          code: 2,
          desc: `On Dialysis optimal PTH range is from ${
            getPthBreakpoints().diaLow
          } ${pthUnit} to ${
            getPthBreakpoints().diaMedium
          } ${pthUnit}. Keep your phosphorus level close to the reference range and/or supplement active Vitamine D. Talk with your doctor.`,
        }
      }
    } else if (lateCKD) {
      if (pth < getPthBreakpoints().ckdMedium) {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Optimal.`,
          code: 1,
          desc: "",
        }
      } else {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Elevated.`,
          code: 2,
          desc: `In Late Stage Kidney Disease PTH should be between ${
            getPthBreakpoints().ckdLow
          } ${pthUnit} to ${
            getPthBreakpoints().ckdMedium
          } ${pthUnit}. Eat a low phosphorus diet and ask your doctor if active Vitamine D is recommended.`,
        }
      }
    } else {
      if (pth < getPthBreakpoints().ckdLow) {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Optimal.`,
          code: 1,
          desc: "",
        }
      } else {
        return {
          text: `Serum PTH ${pth} ${pthUnit}: Elevated.`,
          code: 2,
          desc: `An elevated PTH is an early sign of Bone Mineral Disorders. Keep your phosphorus low by eating a low phosphorus diet! `,
        }
      }
    }
  } else {
    return {
      text: "Serum PTH unknown",
      code: 0,
      desc: "Check your Lab Report for Serum PTH.",
    }
  }
}

const getArticles = (pResult, caResult, pthResult, dialysis) => {
  let articles = []
  if (dialysis && pResult !== 1) {
    articles.push({ slug: "phosphate-control", prio: 3 })
  }
  if (pResult !== 1) {
    articles.push({ slug: "high-phosphorus-foods", prio: 2 })
  }
  return articles
}

const BoneResult = () => {
  const { p, pUnit, ca, caUnit, pth, pthUnit } = useContext(HealthCheckContext)
  const { CKDStage } = useContext(AppContext)

  const pResult =  getPResult(p, pUnit, CKDStage === "Dialysis")
  const caResult = getCaResult(ca, caUnit, CKDStage === "Dialysis")
  const pthResult = getPthResult(
    pth,
    pthUnit,
    CKDStage === "Dialysis",
    CKDStage === "Late Stage Kidney Disease"
  )

  const detail = [pResult, caResult, pthResult]

  const articles = getArticles(pResult, caResult, pthResult, CKDStage === "Dialysis")

  let totalCode = Math.max(...detail.map(result => result.code))
  if ((totalCode === 1) & (detail[0].code === 0)) {
    totalCode = 0
  }

  const summary = { code: totalCode, text: "Bone Mineral Health" }

  return <ResultView summary={summary} detail={detail} articles={articles}/>
}

export default BoneResult

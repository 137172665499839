import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { Typography, Button } from "@material-ui/core"
import { HealthCheckContext } from "../../../../context/healthCheckContext"
import LifestyleResult from "./healthCheckResults/lifestyleResult"
import DiabetesResult from "./healthCheckResults/diabetesControl"
import CvdResult from "./healthCheckResults/cvdResult"
import CkdProgression from "./healthCheckResults/ckdProgression"
import BoneResult from "./healthCheckResults/boneHealth"
import AnemiaControl from "./healthCheckResults/anemiaControl"
import NutritionStatus from "./healthCheckResults/nutritionStatus"
import DialysisCare from "./healthCheckResults/dialysisCare"
import AdvancedCKDCare from "./healthCheckResults/advancedCKDCare"

const useStyles = makeStyles({
  container: {
    padding: "15px",
    textAlign: "left",
    width: "100%",
  },
  buttonBar: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
})

const HealthCheckResults = () => {
  const { setStatus, setStep } = useContext(HealthCheckContext)

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography paragraph variant="h6">
        Health Check Results
      </Typography>

      <LifestyleResult />
      <DiabetesResult />
      <CvdResult />
      <CkdProgression />
      <BoneResult />
      <AnemiaControl />
      <NutritionStatus />
      <DialysisCare />
      <AdvancedCKDCare />

      <div className={classes.buttonBar}>
        <Button
          variant="contained"
          onClick={() => {
            setStep(1)
            setStatus("ongoing")
          }}
        >
          Review Data
        </Button>
      </div>
    </div>
  )
}

export default HealthCheckResults

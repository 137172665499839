import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import {
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepComorbidImage {
    file(relativePath: { eq: "stepComorbid.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  formGroup: {
    margin: "15px 0px",
  },
})

const StepComorbid = () => {
  const {
    diabetes,
    setDiabetes,
    hba1c,
    setHba1c,
    hba1cUnit,
    setHba1cUnit,
    cvd,
    setCvd,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Comorbidities</Typography>
      <Typography paragraph variant="body2">
        Do you suffer from any of the following Health Conditions?
      </Typography>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={diabetes}
              onChange={event => {
                setDiabetes(event.target.checked)
              }}
              name="diabetes"
            />
          }
          label="Diabetes"
        />

        {diabetes ? (
          <ValueInput
            wide
            label="HbA1C"
            value={hba1c}
            setValue={setHba1c}
            allUnits={units.hba1c}
            unit={hba1cUnit}
            setUnit={setHba1cUnit}
          />
        ) : null}
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={cvd}
              onChange={event => {
                setCvd(event.target.checked)
              }}
              name="cvd"
            />
          }
          label="Cardiovascular Disease"
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepComorbid

import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../../context/screenContext"
import HealthCheck from "./tool/labTools/healthCheck";

const useStyles = makeStyles(theme => ({
  toolsContainer: {
    width: "100%",
    maxWidth: "1800px",
    paddingLeft: "auto",
    paddingBottom: "auto",
    paddingTop: "20px",
  },
  toolsContainerMobile: { paddingBottom: "100px" },
}))

const HealthAdvisorMain = () => {
  const screen = useContext(ScreenContext)
  const classes = useStyles()

  return (
    <div
      className={
        screen.tablet ? classes.toolsContainerMobile : classes.toolsContainer
      }
    >
      <HealthCheck />
    </div>
  )
}

export default HealthAdvisorMain
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ResultIcon from "./resultIcon"
import TopicCard from "../../../../topics/topicCard"

const queryArticles = graphql`
  query healthCheckArticles {
    allStrapiTopic {
      nodes {
        category {
          slug
        }
        slug
        title
        subtitle
        coverImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles({
  accordion: {
    width: "100%",
  },
  resultText: {
    marginLeft: "10px",
  },
  list: {
    marginLeft: "40px",
    marginBottom: "15px",
  },
  articleHeader: {
    marginLeft: "25px",
  },
  articles: {
    margin: "10px 15px",
  },
})

const ResultView = ({ summary, detail, articles }) => {
  const classes = useStyles()

  const {
    allStrapiTopic: { nodes: allArticles },
  } = useStaticQuery(queryArticles)

  const getRelatedArticles = selectedArticles => {
    if (!selectedArticles) {
      return []
    }
    const selectedArticlesSlugs = selectedArticles.map(a => a.slug)
    return allArticles.filter(a => selectedArticlesSlugs.includes(a.slug))
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={summary.text}>
        <ResultIcon code={summary.code} />
        <Typography className={classes.resultText}>
          <strong>{summary.text}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column", paddingTop: "0px" }}>
        <List className={classes.list} disablePadding component="ul">
          {detail.map((result, index) => {
            return (
              <ListItem alignItems="flex-start" key={index} disableGutters>
                {detail.length > 1 ? (
                  <ListItemIcon>
                    <ResultIcon code={result.code} />
                  </ListItemIcon>
                ) : null}
                <ListItemText primary={result.text} secondary={result.desc} />
              </ListItem>
            )
          })}
        </List>
        {getRelatedArticles(articles).length > 0 ? (
          <>
            <Typography variant="h6" className={classes.articleHeader}>
              Recommended Articles
            </Typography>
            <Grid container spacing={2} className={classes.articles}>
              {getRelatedArticles(articles).map(article => {
                return (
                  <Grid key={article.slug} item xs={12} sm={4}>
                    <TopicCard
                      small
                      category={article.category}
                      topic={article}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default ResultView

import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { FormGroup, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepProgressionImage {
    file(relativePath: { eq: "stepProgression.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  subheader: {
    marginTop: "10px",
  },
})

const StepProgression = () => {
  const {
    proteinU,
    setProteinU,
    proteinUUnit,
    setProteinUUnit,
    gfr,
    setGfr,
    gfr1y,
    setGfr1y,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">CKD Progression</Typography>
      <Typography paragraph variant="body2">
        Check your recent lab results and fill out the fields as complete as you
        can.
      </Typography>

      <FormGroup>
        <ValueInput
          wide
          label="Urine Albumin"
          value={proteinU}
          setValue={setProteinU}
          allUnits={units.proteinU}
          unit={proteinUUnit}
          setUnit={setProteinUUnit}
        />
      </FormGroup>

      <Typography className={classes.subheader}>
        Do you have your GFR from now and from one year ago?
      </Typography>
      <FormGroup>
        <ValueInput
          wide
          label="GFR (now)"
          value={gfr}
          setValue={setGfr}
          allUnits={units.gfr}
          unit={units.gfr[0]}
          setUnit={() => null}
        />
      </FormGroup>
      <FormGroup>
        <ValueInput
          wide
          label="GFR (year ago)"
          value={gfr1y}
          setValue={setGfr1y}
          allUnits={units.gfr}
          unit={units.gfr[0]}
          setUnit={() => null}
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepProgression

import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"

const getHba1cBreakpoints = hba1cUnit => {
  if (hba1cUnit === "%") {
    return {
      normalLow: 4,
      normalHigh: 5.6,
      diabetesLow: 6.5,
      diabetesMedium: 7.5,
    }
  } else
    return {
      normalLow: 22.2,
      normalHigh: 37.7,
      diabetesLow: 47.5,
      diabetesMedium: 58.5,
    }
}

const getDiabetesResult = (hba1c, hba1cUnit) => {
  if (hba1c > 0) {
    if (hba1c <= getHba1cBreakpoints(hba1cUnit).diabetesLow) {
      return {
        text: `HbA1c ${hba1c} ${hba1cUnit}: Diabetes very well controlled`,
        code: 1,
        desc: "",
      }
    } else if (hba1c <= getHba1cBreakpoints(hba1cUnit).diabetesMedium) {
      return {
        text: `HbA1c ${hba1c} ${hba1cUnit}: Diabetes control ok, but could be improved slightly`,
        code: 2,
        desc: `Diabetes is the number one cause for Kidney Failure. The better you can control your blood sugar, the better it is for your kidneys. The ideal HbA1c would be lower than ${
          getHba1cBreakpoints(hba1cUnit).diabetesLow
        } ${hba1cUnit}.`,
      }
    } else {
      return {
        text: `HbA1c ${hba1c} ${hba1cUnit}: Try to improve your Diabetes control`,
        code: 3,
        desc: `Diabetes is the number one cause for Kidney Failure. The better you can control your blood sugar, the better it is for your kidneys. HbA1c is an indicator of how well you control your blood sugar over a longer time. HbA1c should be at least lower than ${
          getHba1cBreakpoints(hba1cUnit).diabetesMedium
        } ${hba1cUnit}.`,
      }
    }
  } else {
    return {
      text: "HbA1c unknown",
      code: 0,
      desc:
        "Get your HbA1c value from your latest lab test and fill in the form.",
    }
  }
}

const DiabetesResult = () => {
  const { diabetes, hba1c, hba1cUnit } = useContext(HealthCheckContext)

  const detail = [getDiabetesResult(hba1c, hba1cUnit)]

  const summary = { code: detail[0].code, text: "Diabetes Control" }

  if (diabetes) {
    return <ResultView summary={summary} detail={detail} />
  } else return null
}

export default DiabetesResult

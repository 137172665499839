import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"

const getBMI = (height, heightUnit, weight, weightUnit) => {
  if (heightUnit === "ft") {
    height = 30.48 * height
  }
  if (weightUnit === "lb") {
    weight = weight / 2.205
  }
  return weight / (height / 100) ** 2
}

const getBMIResult = (height, heightUnit, weight, weightUnit) => {
  if ((height > 0) & (weight > 0)) {
    const bmi = getBMI(height, heightUnit, weight, weightUnit)
    const bmi_rounded = bmi.toFixed(1)
    if (bmi < 16.5) {
      return {
        text: `BMI ${bmi_rounded}: Serious Underweight`,
        code: 3,
        desc: "",
      }
    } else if (bmi < 18.5) {
      return { text: `BMI ${bmi_rounded}: Underweight`, code: 2, desc: "" }
    } else if (bmi < 25) {
      return { text: `BMI ${bmi_rounded}: Normal Range`, code: 1, desc: "" }
    } else if (bmi < 30) {
      return { text: `BMI ${bmi_rounded}: Overweight`, code: 2, desc: "" }
    } else if (bmi >= 30) {
      return { text: `BMI ${bmi_rounded}: Obese`, code: 3, desc: "" }
    }
  } else {
    return {
      text: "BMI: Unknown",
      code: 0,
      desc: "Fill in your height and weight to compute your BMI.",
    }
  }
}

const getSmokingResult = smoking => {
  if (smoking) {
    return {
      text: "Smoking",
      code: 3,
      desc:
        "Smoking is harmful to the kidneys! It is regarded as an independent predictor of the progression of kidney disease. Stop smoking and make a great impact on your health!",
    }
  } else {
    return { text: "Not Smoking", code: 1, desc: "" }
  }
}

const getFitnessResult = fitnessLevel => {
  if (fitnessLevel === 1) {
    return {
      text: "Fitness Level: Low",
      code: 3,
      desc:
        "Integrate regular exercise into your life to improve muscle strength, blood pressure and sleeping quality.",
    }
  } else if (fitnessLevel < 4) {
    return {
      text: "Fitness Level: Medium",
      code: 2,
      desc: "Devote more time to exercise if you can.",
    }
  } else {
    return { text: "Fitness Level: High", code: 1, desc: "" }
  }
}

const getArticles = (bmiResult, fitnessResult, smokingResult) => {
  let articles = []
  if (bmiResult !== 1 | fitnessResult !== 1 | smokingResult !== 1) {
    articles.push({ slug: "lifestyle-changes", prio: 3 })
  }
  return articles
}

const LifestyleResult = () => {
  const {
    age,
    height,
    heightUnit,
    weight,
    weightUnit,
    smoking,
    exerciseLevel,
  } = useContext(HealthCheckContext)

  const bmiResult = getBMIResult(height, heightUnit, weight, weightUnit)
  const fitnessResult = getFitnessResult(exerciseLevel)
  const smokingResult = getSmokingResult(smoking)

  let detail = []
  if (age < 80) {
    detail = [bmiResult, fitnessResult, smokingResult]
  } else {
    detail = [bmiResult, smokingResult]
  }

  const articles = getArticles(bmiResult, fitnessResult, smokingResult)

  const totalCode = Math.max(...detail.map(result => result.code))

  const summary = { code: totalCode, text: "Lifestyle" }

  return <ResultView summary={summary} detail={detail} articles={articles}/>
}

export default LifestyleResult

import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"
import {
  FormGroup,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepBasicsImage {
    file(relativePath: { eq: "stepBasics.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({})

const StepBasics = () => {
  const {
    hb,
    setHb,
    hbUnit,
    setHbUnit,
    albumin,
    setAlbumin,
    albuminUnit,
    setAlbuminUnit,
    crp,
    setCrp,
    crpUnit,
    setCrpUnit,
    esa,
    setEsa,
  } = useContext(HealthCheckContext)

  const { CKDStage } = useContext(AppContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Basic Lab Values</Typography>
      <Typography paragraph variant="body2">
        Check your recent lab results and supply serum Albumin, CRP and
        Hemoglobin.
      </Typography>

      <FormGroup>
        <ValueInput
          wide
          label="Albumin"
          value={albumin}
          setValue={setAlbumin}
          allUnits={units.albumin}
          unit={albuminUnit}
          setUnit={setAlbuminUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="CRP (C-Reactive Protein)"
          value={crp}
          setValue={setCrp}
          allUnits={units.crp}
          unit={crpUnit}
          setUnit={setCrpUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="Hemoglobin"
          value={hb}
          setValue={setHb}
          allUnits={units.hb}
          unit={hbUnit}
          setUnit={setHbUnit}
        />
      </FormGroup>

      {(CKDStage === "Dialysis") |
      (CKDStage === "Late Stage Kidney Disease") ? (
        <FormGroup row style={{ marginTop: "15px" }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={esa}
                onChange={event => {
                  setEsa(event.target.checked)
                }}
                name="esa"
              />
            }
            label="Regular use of ESA to increase Hemoglobin Levels"
          />
        </FormGroup>
      ) : null}
    </StepLayout>
  )
}

export default StepBasics

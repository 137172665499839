import React, { useContext } from "react"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import {
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepLate2Image {
    file(relativePath: { eq: "stepLate2.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  formGroup: {
    margin: "15px 0px",
  },
})

const StepLateCKD2 = () => {
  const {
    nausea,
    setNausea,
    fatigue,
    setFatigue,
    itching,
    setItching,
    breathShortness,
    setBreathShortness,
    edema,
    setEdema,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Advanced Stage CKD Care - Symptoms</Typography>
      <Typography paragraph variant="body2">
        Do you have any of the following recurring Symptoms?
      </Typography>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={nausea}
              onChange={event => {
                setNausea(event.target.checked)
              }}
              name="nausea"
            />
          }
          label="Nausea or Vomiting"
        />
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={fatigue}
              onChange={event => {
                setFatigue(event.target.checked)
              }}
              name="fatigue"
            />
          }
          label="Fatigue (constant feeling of tiredness and lack of energy)"
        />
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={itching}
              onChange={event => {
                setItching(event.target.checked)
              }}
              name="itching"
            />
          }
          label="Itching and very dry skin"
        />
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={breathShortness}
              onChange={event => {
                setBreathShortness(event.target.checked)
              }}
              name="breathShortness"
            />
          }
          label="Shortness of Breath"
        />
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={edema}
              onChange={event => {
                setEdema(event.target.checked)
              }}
              name="edema"
            />
          }
          label="Edema (Swellings, e.g. around the feet and ankles)"
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepLateCKD2

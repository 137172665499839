import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getHbBreakpoints = hbUnit => {
  if (hbUnit === "g/dL") {
    return {
      lowDia: 10,
      highDia: 11.5,
      lowNormF: 12,
      highNormF: 15.5,
      lowNormM: 13.5,
      highNormM: 17.5,
    }
  } else
    return {
      lowDia: 6.2,
      highDia: 7.2,
      lowNormF: 7.45,
      highNormF: 9.6,
      lowNormM: 8.3,
      highNormM: 10.9,
    }
}

const getHbResult = (hb, hbUnit, gender, esa, CKDStage) => {
  if (hb > 0) {
    if ((hb < getHbBreakpoints(hbUnit).lowDia) & !esa) {
      return {
        text: `Hemoglobin ${hb} ${hbUnit}: Too low, Action required.`,
        code: 2,
        desc:
          "Talk with your doctor about the reason for your low Hb (iron deficiency? inflammation?). Use of ESA may be required.",
      }
    } else if ((hb < getHbBreakpoints(hbUnit).lowDia) & esa) {
      return {
        text: `Hemoglobin ${hb} ${hbUnit}: Too low, Adjustment of therapy required.`,
        code: 2,
        desc:
          "Talk with your doctor about your therapy (ESA dose, iron usage).",
      }
    }
    if (
      (CKDStage === "Dialysis") |
      (CKDStage === "Late Stage Kidney Disease")
    ) {
      if (esa & (hb <= getHbBreakpoints(hbUnit).highDia)) {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: Within recommended range when using ESA.`,
          code: 1,
          desc: "",
        }
      } else if (esa & (hb > getHbBreakpoints(hbUnit).highDia)) {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: It's not recommended to raise Hb above ${
            getHbBreakpoints(hbUnit).highDia
          } ${hbUnit} using ESA.`,
          code: 2,
          desc: "Ask your doctor about your ESA dose.",
        }
      } else if (
        ((gender === "female") & (hb < getHbBreakpoints(hbUnit).lowNormF)) |
        ((gender === "male") & (hb < getHbBreakpoints(hbUnit).lowNormM))
      ) {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: Below reference range, but still ok in your situation.`,
          code: 1,
          desc: `Start of ESA therapy is recommended only when your Hb falls below ${
            getHbBreakpoints(hbUnit).lowDia
          } ${hbUnit}. Ask your doctor about other potential causes of anemia (iron deficiency, inflammatory states).`,
        }
      } else if (
        ((gender === "female") & (hb <= getHbBreakpoints(hbUnit).highNormF)) |
        ((gender === "male") & (hb <= getHbBreakpoints(hbUnit).highNormM))
      ) {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: Optimal, if you don't use ESA.`,
          code: 1,
          desc: ``,
        }
      } else {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: It's unlikely you have elevated Hb without ESA use in advanced CKD or with dialysis.`,
          code: 0,
          desc: `Check if you use ESA to raise Hemoglobin.`,
        }
      }
    } else {
      if (
        ((gender === "female") & (hb < getHbBreakpoints(hbUnit).lowNormF)) |
        ((gender === "male") & (hb < getHbBreakpoints(hbUnit).lowNormM))
      ) {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: Below reference range, but could be still acceptable.`,
          code: 2,
          desc: `Use of ESA not recommended. Ask your doctor about other potential causes of anemia (iron deficiency, inflammatory states).`,
        }
      } else {
        return {
          text: `Hemoglobin ${hb} ${hbUnit}: Optimal, if you don't use ESA.`,
          code: 1,
          desc: ``,
        }
      }
    }
  } else {
    return {
      text: "Hemoglobin unknown",
      code: 0,
      desc: "Regular follow-up of Hemoglobin Levels is recommended.",
    }
  }
}

const getArticles = (hbResult) => {
  let articles = []
  if (hbResult !== 1) {
    articles.push({ slug: "anemia-management", prio: 3 })
  }
  return articles
}

const AnemiaControl = () => {
  const { CKDStage } = useContext(AppContext)
  const { hb, hbUnit, esa, gender } = useContext(HealthCheckContext)

  const hbResult = getHbResult(hb, hbUnit, gender, esa, CKDStage)

  const detail = [hbResult]

  const articles = getArticles(hbResult)

  const summary = { code: detail[0].code, text: "Anemia Control" }

  return <ResultView summary={summary} detail={detail} articles={articles}/>
}

export default AnemiaControl

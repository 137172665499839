import React, { useContext } from "react"
import { Paper, MobileStepper, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { HealthCheckContext } from "../../../../context/healthCheckContext"
import { ScreenContext } from "../../../../context/screenContext"
import { AppContext } from "../../../../context/appContext"
import StepGeneral from "./healthCheckSteps/stepGeneral"
import StepComorbid from "./healthCheckSteps/stepComorbid"
import StepBasics from "./healthCheckSteps/stepBasics"
import StepProgression from "./healthCheckSteps/stepProgression"
import StepHeart from "./healthCheckSteps/stepHeart"
import StepBones from "./healthCheckSteps/stepBones"
import StepLateCKD from "./healthCheckSteps/stepLateCKD"
import StepDialysis from "./healthCheckSteps/stepDialysis"
import StepLateCKD2 from "./healthCheckSteps/stepLateCKD2"

const useStyles = makeStyles({
  stepperWidget: {
    marginBottom: "15px",
    width: "100%",
  },
  stepperContainer: {
    flexGrow: 1,
    padding: "20px 40px",
    minHeight: "450px",
  },
  stepperContainerMobile: {
    flexGrow: 1,
    padding: "10px 20px",
    minHeight: "400px",
  },
})

const HealthAssessment = () => {
  const classes = useStyles()
  const { CKDStage } = useContext(AppContext)
  const { mobile } = useContext(ScreenContext)
  const { step, setStep, setStatus } = useContext(HealthCheckContext)

  const getAllSteps = () => {
    if (CKDStage === "Early Stage Kidney Disease") {
      return [
        <StepGeneral />,
        <StepComorbid />,
        <StepBasics />,
        <StepProgression />,
        <StepHeart />,
        <StepBones />,
      ]
    } else if (CKDStage === "Late Stage Kidney Disease") {
      return [
        <StepGeneral />,
        <StepComorbid />,
        <StepBasics />,
        <StepHeart />,
        <StepBones />,
        <StepLateCKD />,
        <StepLateCKD2 />,
      ]
    } else if (CKDStage === "Dialysis") {
      return [
        <StepGeneral />,
        <StepComorbid />,
        <StepBasics />,
        <StepHeart />,
        <StepBones />,
        <StepDialysis />,
      ]
    } else if (CKDStage === "(Post) Transplant") {
      return [
        <StepGeneral />,
        <StepComorbid />,
        <StepBasics />,
        <StepProgression />,
        <StepHeart />,
        <StepBones />,
      ]
    }
  }

  const getStep = step => {
    return getAllSteps()[step - 1]
  }

  const BackButton = () => {
    return (
      <Button
        size="small"
        onClick={() => setStep(step - 1)}
        disabled={step === 1}
      >
        <KeyboardArrowLeft />
        Back
      </Button>
    )
  }

  const NextButton = () => {
    return (
      <Button
        size="small"
        onClick={() => setStep(step + 1)}
        disabled={step === getAllSteps().length}
      >
        Next
        <KeyboardArrowRight />
      </Button>
    )
  }

  const FinishButton = () => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => setStatus("finished")}
      >
        Finish
      </Button>
    )
  }

  const StepperNav = () => {
    return (
      <MobileStepper
        steps={getAllSteps().length}
        position="static"
        variant="dots"
        activeStep={step - 1}
        nextButton={
          step === getAllSteps().length ? <FinishButton /> : <NextButton />
        }
        backButton={<BackButton />}
      />
    )
  }

  return (
    <>
      <Paper elevation={1} className={classes.stepperWidget}>
        <Paper
          elevation={0}
          className={
            mobile ? classes.stepperContainerMobile : classes.stepperContainer
          }
        >
          {getStep(step)}
        </Paper>
        <StepperNav />
      </Paper>
    </>
  )
}

export default HealthAssessment

import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getPuBreakpoints = () => {
  return {
    normal: 30,
    low: 300,
    medium: 1000,
  }
}

const getPuResult = (proteinU, proteinUUnit) => {
  if (proteinU > 0) {
    if (proteinUUnit === "g/L") {
      proteinU = proteinU * 1000
    }
    if (proteinU <= getPuBreakpoints().normal) {
      return {
        text: `Urine Albumin ${proteinU} mg/L: In normal range! `,
        code: 1,
        desc: "",
      }
    } else if (proteinU <= getPuBreakpoints().low) {
      return {
        text: `Urine Albumin ${proteinU} mg/L: Slightly elevated (Microalbuminuria)`,
        code: 1,
        desc: `Lower Urine Protein (Albumin) is strongly associated with slower Progression of Kidney Disease.`,
      }
    } else if (proteinU <= getPuBreakpoints().medium) {
      return {
        text: `Urine Albumin ${proteinU} mg/L: Elevated (Macroalbuminuria)`,
        code: 2,
        desc: `The Amount of Protein lost in the Urine is the most powerful predictor of Kidney Failure. Try to reduce Albuminuria to less than ${
          getPuBreakpoints().low
        } mg/L by strict blood pressure control and diet changes (plant based, lower protein).`,
      }
    } else {
      return {
        text: `Urine Albumin ${proteinU} mg/L: Elevated (Macroalbuminuria)`,
        code: 3,
        desc: `The Amount of Protein lost in the Urine is the most powerful predictor of Kidney Failure. Try to reduce Albuminuria to at least less than ${
          getPuBreakpoints().medium
        } mg/L by strict blood pressure control and diet changes (plant based, lower protein).`,
      }
    }
  } else {
    return {
      text: "Urine Albumin unknown",
      code: 0,
      desc: "You can test it by yourself using a Urine Test Strip.",
    }
  }
}

const getGfrResult = (gfr, gfr1y) => {
  if ((gfr > 0) & (gfr1y > 0)) {
    if (gfr1y - gfr <= 1) {
      return {
        text: `Based on the one year period, your GFR seems to be fairly stable.`,
        code: 1,
        desc: "",
      }
    } else {
      const yearsToESRD = Math.round((gfr - 10) / (gfr1y - gfr))
      if (yearsToESRD < 2) {
        return {
          text: `Based on the one year period, you may have to start dialysis within 2 years.`,
          code: 2,
          desc:
            "Kidney Disease Progression seems to be quite fast, try to slow down Progression by lifestyle and diet changes.",
        }
      } else {
        return {
          text: `Estimated Time to Kidney Failure: ${yearsToESRD} years (based on the data for the one year period).`,
          code: 2,
          desc:
            "Try to slow down Kidney Disease Progression by lifestyle and diet changes.",
        }
      }
    }
  } else {
    return {
      text: "GFR decline unknown",
      code: 0,
      desc: "Follow up your GFR",
    }
  }
}

const getArticles = (puResult, gfrResult) => {
  let articles = []
  if (puResult !== 1 || gfrResult !== 1) {
    articles.push({ slug: "slow-down-disease-progression", prio: 3 })
  }
  return articles
}

const CkdProgression = () => {
  const { proteinU, proteinUUnit, gfr, gfr1y } = useContext(HealthCheckContext)
  const { CKDStage } = useContext(AppContext)

  const puResult = getPuResult(proteinU, proteinUUnit)
  const gfrResult = getGfrResult(gfr, gfr1y)

  const detail = [puResult, gfrResult]

  const articles = getArticles(puResult, gfrResult)

  const totalCode = Math.max(...detail.map(result => result.code))

  const summary = { code: totalCode, text: "Kidney Disease Progression" }

  if (
    (CKDStage === "Early Stage Kidney Disease") |
    (CKDStage === "(Post) Transplant")
  ) {
    return <ResultView summary={summary} detail={detail} articles={articles}/>
  } else return null
}

export default CkdProgression

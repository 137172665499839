import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getBpBreakpoints = () => {
  return {
    lowSys: 130,
    lowDia: 80,
    mediumSys: 140,
    mediumDia: 85,
  }
}

const getBpResult = (bpSys, bpDia) => {
  if ((bpSys > 0) & (bpDia > 0)) {
    if (
      (bpSys <= getBpBreakpoints().lowSys) &
      (bpDia <= getBpBreakpoints().lowDia)
    ) {
      return {
        text: `Blood pressure ${bpSys}/${bpDia}: Well controlled`,
        code: 1,
        desc: "",
      }
    } else if (
      (bpSys <= getBpBreakpoints().mediumSys) &
      (bpDia <= getBpBreakpoints().mediumDia)
    ) {
      return {
        text: `Blood pressure ${bpSys}/${bpDia}: Slightly too high, but the optimal range is within reach.`,
        code: 2,
        desc: `Your average blood pressure should be below ${
          getBpBreakpoints().lowSys
        }/${getBpBreakpoints().lowDia}.`,
      }
    } else {
      return {
        text: `Blood pressure ${bpSys}/${bpDia}:  Too high, try to get closer to the optimal range.`,
        code: 3,
        desc: `As a first step try to get it below ${
          getBpBreakpoints().mediumSys
        }/${getBpBreakpoints().mediumDia}.`,
      }
    }
  } else {
    return {
      text: "Blood pressure unknown",
      code: 0,
      desc:
        "With Kidney Disease it's a good practice to measure your blood pressure by yourself regularly. ",
    }
  }
}

const getLdlBreakpoints = ldlUnit => {
  if (ldlUnit === "mg/dL") {
    return {
      low: 70,
      medium: 100,
    }
  } else
    return {
      low: 1.8,
      medium: 2.6,
    }
}

const getLdlResult = (ldl, ldlUnit) => {
  if (ldl > 0) {
    if (ldl <= getLdlBreakpoints(ldlUnit).low) {
      return {
        text: `LDL Cholesterol ${ldl} ${ldlUnit}: Optimal`,
        code: 1,
        desc: "",
      }
    } else if (ldl <= getLdlBreakpoints(ldlUnit).medium) {
      return {
        text: `LDL Cholesterol ${ldl} ${ldlUnit}: For healthy people ok, but with Kidney Disease LDL Optimum is lower.`,
        code: 2,
        desc: `The optimal LDL Cholesterol Level for people with high CVD risk is below ${
          getLdlBreakpoints(ldlUnit).low
        } ${ldlUnit}. LDL is best reduced by diet and lifestyle changes.`,
      }
    } else {
      return {
        text: `LDL Cholesterol ${ldl} ${ldlUnit}: Too high. With Kidney Disease you also at higher risk of Cardiovascular Disease.`,
        code: 3,
        desc: `As a first step try to get your LDL Cholesterol below ${
          getLdlBreakpoints(ldlUnit).medium
        } ${ldlUnit}. LDL is best reduced by diet and lifestyle changes.`,
      }
    }
  } else {
    return {
      text: "LDL Cholesterol unknown",
      code: 0,
      desc:
        "With Kidney Disease it's important to care for CVD Health and your Cholesterol Levels.",
    }
  }
}

const getTrigBreakpoints = trigUnit => {
  if (trigUnit === "mg/dL") {
    return {
      low: 150,
      medium: 200,
    }
  } else
    return {
      low: 1.7,
      medium: 2.2,
    }
}

const getTrigResult = (trig, trigUnit) => {
  if (trig > 0) {
    if (trig <= getTrigBreakpoints(trigUnit).low) {
      return {
        text: `Triglycerides ${trig} ${trigUnit}: Normal Range`,
        code: 1,
        desc: "",
      }
    } else if (trig <= getTrigBreakpoints(trigUnit).medium) {
      return {
        text: `Triglycerides ${trig} ${trigUnit}: Elevated.`,
        code: 2,
        desc: `The Triglyceride Level should be below ${
          getTrigBreakpoints(trigUnit).low
        } ${trigUnit}.`,
      }
    } else {
      return {
        text: `Triglycerides ${trig} ${trigUnit}: High.`,
        code: 3,
        desc: `As a first step try to get your Triglycerides below ${
          getTrigBreakpoints(trigUnit).medium
        } ${trigUnit}. Consider lifestyle and diet changes.`,
      }
    }
  } else {
    return {
      text: "Levels of Triglycerides unknown",
      code: 0,
      desc: "Review your lab results or ask your doctor.",
    }
  }
}

const getArticles = (bpResult, ldlResult, trigResult, dialysis) => {
  let articles = []
  if (dialysis && bpResult.code !== 1) {
    articles.push({ slug: "volume-control", prio: bpResult.code })
    articles.push({ slug: "blood-pressure-management", prio: bpResult.code })
  }
  if (ldlResult.code !== 1) {
    articles.push({ slug: "lowering-cholesterol-levels", prio: ldlResult.code })
  }
  return articles
}

const CvdResult = () => {
  const { bpSys, bpDia, ldl, ldlUnit, trig, trigUnit } = useContext(
    HealthCheckContext
  )
  const { CKDStage } = useContext(AppContext)

  const bpResult = getBpResult(bpSys, bpDia)
  const ldlResult = getLdlResult(ldl, ldlUnit)
  const trigResult = getTrigResult(trig, trigUnit)

  const detail = [bpResult, ldlResult, trigResult]

  const articles = getArticles(bpResult, ldlResult, trigResult, CKDStage === "Dialysis")

  let totalCode = Math.max(...detail.map(result => result.code))
  if ((totalCode === 1) & ((detail[0].code === 0) | (detail[1].code === 0))) {
    totalCode = 0
  }

  const summary = { code: totalCode, text: "Cardiovascular Status" }

  return <ResultView summary={summary} detail={detail} articles={articles} />
}

export default CvdResult

import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getKBreakpoints = () => {
  return {
    lowNormal: 3.5,
    highNormal: 5,
    diaHigh: 5.6,
    diaCritical: 6,
  }
}

const getKResult = (k, kUnit) => {
  if (k > 0) {
    if (k < getKBreakpoints().lowNormal) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Below normal`,
        code: 2,
        desc: `Potassium Deficiency is less common in Kidney Disease. Check your Lab Results again and talk with your doctor.`,
      }
    } else if (k <= getKBreakpoints().highNormal) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Within the reference range`,
        code: 1,
        desc: ``,
      }
    } else if (k <= getKBreakpoints().diaHigh) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Slightly above normal`,
        code: 2,
        desc: `Try to reduce high Potassium foods and talk to your doctor in case you take ACE inhibitors. They also increase the Potassium Levels in your blood.`,
      }
    } else {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Elevated.`,
        code: 3,
        desc: `Eliminate high Potassium foods and talk to your doctor in case you take ACE inhibitors. They also increase the Potassium Levels in your blood. Dependent on other conditions, start of dialysis treatment might be required.`,
      }
    }
  } else {
    return {
      text: "Serum Potassium unknown",
      code: 0,
      desc: "Get your Potassium Value from your Lab Results.",
    }
  }
}

const getBeBreakpoints = () => {
  return {
    lowDia: -4,
    lowNormal: -2,
    highNormal: 2,
  }
}

const getBeResult = (be, beUnit) => {
  if ((be !== null) & (be !== "") & (be > -20) & (be < 20)) {
    if (be < getBeBreakpoints().lowDia) {
      return {
        text: `Base Excess ${be} ${beUnit}: Considerable Base Deficit (Acidosis)`,
        code: 3,
        desc: `Try to keep Acid-Base balanced, i.e. BE above -2 ${beUnit}. Use oral sodium bicarbonate (bicarb of soda) to correct Acidosis.`,
      }
    } else if (be < getBeBreakpoints().lowNormal) {
      return {
        text: `Base Excess ${be} ${beUnit}: Base Deficit (Acidosis)`,
        code: 2,
        desc: `Try to keep Acid-Base balanced, i.e. BE above -2 ${beUnit}. Use oral sodium bicarbonate (bicarb of soda) to correct Acidosis.`,
      }
    } else if (be <= getBeBreakpoints().highNormal) {
      return {
        text: `Base Excess ${be} ${beUnit}: Optimal`,
        code: 1,
        desc: ``,
      }
    } else {
      return {
        text: `Base Excess ${be} ${beUnit}: Base Excess (Alkalosis)`,
        code: 2,
        desc: `Alkalosis is not very common in kidney disease. There are commonly no adverse consequences of base excess, but talk with your doctor.`,
      }
    }
  } else {
    return {
      text: "Base Excess (BE) unknown",
      code: 0,
      desc: "You may find BE value on Blood Gas Analyzer Report.",
    }
  }
}

const getHydrationResult = (age, cvd, bpSys, breathShortness, edema) => {
  if (breathShortness | edema) {
    let symptoms = ""
    if (breathShortness & edema) {
      symptoms = "Edema and Shortness of Breath"
    } else if (breathShortness) {
      symptoms = "Shortness of Breath"
    } else {
      symptoms = "Edema"
    }
    return {
      text: `Possible Symptoms of Overhydration: ${symptoms}`,
      code: 2,
      desc: `Chronic Overhydration places an additional burden on your Heart. ${
        bpSys > 135
          ? "This may also partially explain your elevated Blood Pressure."
          : ""
      } Check your daily fluid balance (intake and loss) and ask your doctor about diagnosis and therapy of overhydration.`,
    }
  } else if ((age < 65) & !cvd & (bpSys > 135)) {
    return {
      text: `Be aware of Overhydration`,
      code: 2,
      desc: `Even you don't have obvious Symptoms (such as Edema) make sure your elevated Blood Pressure is not (at least partially) caused by Overhydration.`,
    }
  } else {
    return {
      text: `No obvious signs of Overhydration`,
      code: 0,
      desc: `Rely on your doctor for Diagnosis (and Therapy) of Overhydration.`,
    }
  }
}

const getUremiaResult = (nausea, fatigue, itching) => {
  if (nausea | fatigue | itching) {
    return {
      text: `First Symptoms of Kidney Failure`,
      code: 2,
      desc: `Most of the Symptoms of late CKD (such as fatigue or nausea) may be improved by a low protein plant based diet.`,
    }
  } else {
    return {
      text: `No Symptoms of Kidney Failure `,
      code: 1,
      desc: ``,
    }
  }
}

const getArticles = (kResult, beResult, hydrationResult, uremiaResult) => {
  let articles = []
  if (kResult !== 1 || beResult !== 1 || hydrationResult !==1 || uremiaResult !== 1) {
    articles.push({ slug: "when-to-start-dialysis", prio: 3 })
  }
  return articles
}

const AdvancedCKDCare = () => {
  const { CKDStage } = useContext(AppContext)
  const {
    k,
    kUnit,
    be,
    beUnit,
    bpSys,
    cvd,
    age,
    nausea,
    fatigue,
    itching,
    breathShortness,
    edema,
  } = useContext(HealthCheckContext)

  const kResult = getKResult(k, kUnit)
  const beResult = getBeResult(be, beUnit)
  const hydrationResult = getHydrationResult(age, cvd, bpSys, breathShortness, edema)
  const uremiaResult = getUremiaResult(nausea, fatigue, itching)

  const detail = [kResult, beResult, hydrationResult, uremiaResult]

  const articles = getArticles(kResult, beResult, hydrationResult, uremiaResult)

  let totalCode = Math.max(...detail.map(result => result.code))

  const summary = {
    code: totalCode,
    text: "Advanced CKD Care",
  }

  if (CKDStage === "Late Stage Kidney Disease") {
    return <ResultView summary={summary} detail={detail} articles={articles}/>
  } else return null
}

export default AdvancedCKDCare

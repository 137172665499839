import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HealthAdvisorMain from "../components/tools/healthAdvisorMain";

const ToolsPage = () => {
  
  return (
    <Layout activePage="healthAdvisor">
      <SEO title="Health Advisor" />
      <HealthAdvisorMain />
    </Layout>
  )
}

export default ToolsPage

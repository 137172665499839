import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getKBreakpoints = () => {
  return {
    lowNormal: 3.5,
    highNormal: 5,
    diaHigh: 5.6,
    diaCritical: 6,
  }
}

const getKResult = (k, kUnit) => {
  if (k > 0) {
    if (k < getKBreakpoints().lowNormal) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Below normal`,
        code: 2,
        desc: `Very unlikely if measured before dialysis treatment. Check your Lab again.`,
      }
    } else if (k <= getKBreakpoints().highNormal) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Within the reference range`,
        code: 1,
        desc: ``,
      }
    } else if (k <= getKBreakpoints().diaHigh) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Slightly above normal, but still acceptable.`,
        code: 1,
        desc: ``,
      }
    } else if (k <= getKBreakpoints().diaCritical) {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Elevated.`,
        code: 2,
        desc: `Try to keep Potassium Levels below ${
          getKBreakpoints().diaHigh
        } ${kUnit} to be on the safe side. Eliminate high Potassium foods and talk to your doctor in case you take ACE inhibitors. They also increase the Potassium Levels in your blood.`,
      }
    } else {
      return {
        text: `Serum Potassium ${k} ${kUnit}: Critically Elevated.`,
        code: 3,
        desc: `Potassium Levels above ${
          getKBreakpoints().diaCritical
        } ${kUnit} are dangerous and potentially life-threatening. Eliminate high Potassium foods and talk to your doctor in case you take ACE inhibitors. They also increase the Potassium Levels in your blood.`,
      }
    }
  } else {
    return {
      text: "Serum Potassium unknown",
      code: 0,
      desc: "Get your Potassium Value from your Lab Results.",
    }
  }
}

const getBeBreakpoints = () => {
  return {
    lowDia: -4,
    lowNormal: -2,
    highNormal: 2,
  }
}

const getBeResult = (be, beUnit) => {
  if ((be !== null) & (be !== "") & (be > -20) & (be < 20)) {
    if (be < getBeBreakpoints().lowDia) {
      return {
        text: `Base Excess ${be} ${beUnit}: Considerable Base Deficit (Acidosis)`,
        code: 3,
        desc: `Try to keep Acid-Base balanced, BE at least above -4 ${beUnit}. Use oral sodium bicarbonate (bicarb of soda) on dialysis free days. Acidosis can also lead to elevated Potassium Levels.`,
      }
    } else if (be < getBeBreakpoints().lowNormal) {
      return {
        text: `Base Excess ${be} ${beUnit}: Base Deficit (Acidosis)`,
        code: 2,
        desc: `Try to keep Acid-Base balanced, i.e. BE above -2 ${beUnit}. Use oral sodium bicarbonate (bicarb of soda) on dialysis free days.`,
      }
    } else if (be <= getBeBreakpoints().highNormal) {
      return {
        text: `Base Excess ${be} ${beUnit}: In normal range. Check also that it is still within or near normal range after dialysis treatment.`,
        code: 1,
        desc: ``,
      }
    } else {
      return {
        text: `Base Excess ${be} ${beUnit}: Base Excess (Alkalosis)`,
        code: 1,
        desc: `This is a very unlikely condition when measured before dialysis. Check your lab again or ask your doctor. `,
      }
    }
  } else {
    return {
      text: "Base Excess (BE) unknown",
      code: 0,
      desc: "You may find BE value on Blood Gas Analyzer Report.",
    }
  }
}

const getUrBreakpoints = () => {
  return {
    medium: 9,
    high: 12,
  }
}

const getUrResult = (weight, weightUnit, weightGain, diaLen) => {
  if (weight > 0) {
    if (weightUnit === "lb") {
      weight = weight / 2.205
    }
    const ur = ((weightGain * 1000) / weight / diaLen).toFixed(1)
    if (ur <= getUrBreakpoints().medium) {
      return {
        text: `Ultrafiltration Rate ${ur} ml/h/kg: Optimal`,
        code: 1,
        desc: `Ultrafiltration Rate is the Amount of Fluid removed by kg of Bodyweight and per Hour on Dialysis. The lower the rate of removal of excess fluid the better it is for your Health.`,
      }
    } else if (ur <= getUrBreakpoints().high) {
      const wg = ((getUrBreakpoints().medium * weight * diaLen) / 1000).toFixed(
        1
      )
      return {
        text: `Ultrafiltration Rate ${ur} ml/h/kg: Slightly higher than optimal`,
        code: 2,
        desc: `Ultrafiltration Rate is the Amount of Fluid removed by kg of Bodyweight and per Hour on Dialysis. Try to keep your weight gain between two dialysis sessions below ${wg} kg.`,
      }
    } else {
      const wg = ((getUrBreakpoints().high * weight * diaLen) / 1000).toFixed(1)
      return {
        text: `Ultrafiltration Rate ${ur} ml/h/kg: Higher than optimal`,
        code: 3,
        desc: `Ultrafiltration Rate is the Amount of Fluid removed by kg of Bodyweight and per Hour on Dialysis. As a first step try to keep your weight gain between two dialysis sessions below ${wg} kg.`,
      }
    }
  } else {
    return {
      text: "Ultrafiltration Rate unknown",
      code: 0,
      desc:
        "Ultrafiltration Rate is the Amount of Fluid removed by kg of Bodyweight and per Hour on Dialysis. Supply your Bodyweight to compute this indicator.",
    }
  }
}

const getArticles = (kResult, beResult, urResult) => {
  let articles = []
  if (kResult !== 1) {
    articles.push({ slug: "potassium-control", prio: 3 })
  }
  if (beResult !== 1) {
    articles.push({ slug: "acid-base-balance", prio: 2 })
  }
  return articles
}

const DialysisCare = () => {
  const { CKDStage } = useContext(AppContext)
  const {
    k,
    kUnit,
    be,
    beUnit,
    diaLen,
    weightGain,
    weight,
    weightUnit,
  } = useContext(HealthCheckContext)

  const kResult = getKResult(k, kUnit)
  const beResult = getBeResult(be, beUnit)
  const urResult = getUrResult(weight, weightUnit, weightGain, diaLen)

  const detail = [kResult, beResult, urResult]

  const articles = getArticles(kResult, beResult, urResult)

  let totalCode = Math.max(...detail.map(result => result.code))
  if ((totalCode === 1) & (detail[0].code === 0)) {
    totalCode = 0
  }

  const summary = {
    code: totalCode,
    text: "Dialysis Care",
  }

  if (CKDStage === "Dialysis") {
    return <ResultView summary={summary} detail={detail} articles={articles}/>
  } else return null
}

export default DialysisCare

import React, { useContext } from "react"
import ResultView from "./resultTemplate"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { AppContext } from "../../../../../context/appContext"

const getAlbuminBreakpoints = albuminUnit => {
  if (albuminUnit === "mg/dL") {
    return {
      lowNormal: 3.4,
      medium: 4,
    }
  } else if (albuminUnit === "mg/L") {
    return {
      lowNormal: 34,
      medium: 40,
    }
  }
}

const getAlbuminResult = (albumin, albuminUnit) => {
  if (albumin > 0) {
    if (albumin < getAlbuminBreakpoints(albuminUnit).lowNormal) {
      return {
        text: `Serum Albumin ${albumin} ${albuminUnit}: Below normal`,
        code: 3,
        desc: `Decreased Serum Albumin reflects protein deficiency and/or inflammatory status. Consider use of Amino Acids for Protein Supplementation and switch to Healthy Lifestyle and Diet.`,
      }
    } else if (albumin < getAlbuminBreakpoints(albuminUnit).medium) {
      return {
        text: `Serum Albumin ${albumin} ${albuminUnit}: Within reference but should be ideally above ${
          getAlbuminBreakpoints(albuminUnit).medium
        }.`,
        code: 2,
        desc: `Albumin of ${
          getAlbuminBreakpoints(albuminUnit).medium
        } or greater is optimal when it comes to CKD Progression and Outcome. Albumin reflects nutritional and inflammatory status. Consider amino acid supplements and switch to a Healthy Lifestyle and Diet.`,
      }
    } else {
      return {
        text: `Serum Albumin ${albumin} ${albuminUnit}: Optimal`,
        code: 1,
        desc: ``,
      }
    }
  } else {
    return {
      text: "Serum Albumin unknown",
      code: 0,
      desc:
        "Albumin is one of the key prognostic factors of CKD Progression and Outcome.",
    }
  }
}

const getCrpBreakpoints = crpUnit => {
  if (crpUnit === "mg/dL") {
    return {
      ideal: 0.1,
      normalHigh: 0.5,
    }
  } else if (crpUnit === "mg/L") {
    return {
      ideal: 1,
      normalHigh: 5,
    }
  }
}

const getCrpResult = (crp, crpUnit) => {
  if (crp > 0) {
    if (crp < getCrpBreakpoints(crpUnit).ideal) {
      return {
        text: `Serum CRP ${crp} ${crpUnit}: Optimal`,
        code: 1,
        desc: ``,
      }
    } else if (crp < getCrpBreakpoints(crpUnit).normalHigh) {
      return {
        text: `Serum CRP ${crp} ${crpUnit}: Within reference range, however scientific evidence shows that lower CRP is optimal.`,
        code: 2,
        desc: `CRP reflects inflammatory state and CVD risk. Ideal CRP values are up to ${
          getCrpBreakpoints(crpUnit).ideal
        } ${crpUnit}. Lower CRP by Diet and Lifestyle Changes.`,
      }
    } else {
      return {
        text: `Serum CRP ${crp} ${crpUnit}: Elevated.`,
        code: 2,
        desc: `CRP is a strong predictor of CKD progression, Cardiovascular Complications and overall Survival on Dialysis. As a first step try to bring your CRP below ${
          getCrpBreakpoints(crpUnit).normalHigh
        } ${crpUnit}. In many cases this can be achieved by Diet and Lifestyle Changes.`,
      }
    }
  } else {
    return {
      text: "Serum CRP unknown",
      code: 0,
      desc:
        "CRP is a major predictor of CKD progression, Cardiovascular Complications and overall Survival on Dialysis.",
    }
  }
}

const getArticles = (albuminResult, crpResult, dialysis) => {
  let articles = []
  if (dialysis && (albuminResult !== 1 || crpResult !== 1)) {
    articles.push({ slug: "dialysis-lab", prio: 3 })
  }
  if (crpResult !== 1) {
    articles.push({ slug: "c-reactive-protein-crp", prio: 2 })
  }
  return articles
}

const NutritionStatus = () => {
  const { albumin, albuminUnit, crp, crpUnit } = useContext(HealthCheckContext)
  const { CKDStage } = useContext(AppContext)

  const albuminResult = getAlbuminResult(albumin, albuminUnit)
  const crpResult = getCrpResult(crp, crpUnit)

  const detail = [albuminResult, crpResult]

  const articles = getArticles(albuminResult, crpResult, CKDStage==="Dialysis")

  let totalCode = Math.max(...detail.map(result => result.code))
  if ((totalCode === 1) & (detail[0].code === 0)) {
    totalCode = 0
  }

  const summary = {
    code: totalCode,
    text: "Protein Nutrition/ Inflammation Status",
  }

  return <ResultView summary={summary} detail={detail} articles={articles}/>
}

export default NutritionStatus

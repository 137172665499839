import React, { useContext } from "react"
import { Toolbar, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { AppContext } from "../../../../context/appContext"
import { HealthCheckContext } from "../../../../context/healthCheckContext"
import HealthCheckStart from "./healthCheckStart"
import HealthAssessment from "./healthAssessment"
import HealthCheckResults from "./healthCheckResults"
import CKDStageDialog from "../../../ckdStageDialog"

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: "15px",
    marginBottom: "10px",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "10px",
  },
  caption: {
    color: "white",
    flexGrow: 1,
    fontSize: "1.1em",
  },
}))

const HealthCheck = () => {
  const { CKDStage } = useContext(AppContext)
  const { status } = useContext(HealthCheckContext)

  const classes = useStyles()

  const getCurrentView = () => {
    if (status === "start") {
      return <HealthCheckStart />
    } else if (status === "ongoing") {
      return <HealthAssessment />
    } else if (status === "finished") {
      return <HealthCheckResults />
    }
  }

  return (
    <>
      <Toolbar variant="dense" className={classes.header}>
        <Typography variant="overline" align="left" className={classes.caption}>
          Health Advisor
        </Typography>
      </Toolbar>
      <Paper component={Toolbar} className={classes.toolbar}>
        {!CKDStage ? (
          <CKDStageDialog open={true} setOpen={() => null} />
        ) : (
          getCurrentView()
        )}
      </Paper>
    </>
  )
}

export default HealthCheck

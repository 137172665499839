import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import {
  FormGroup,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Slider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import NumberFormat from "react-number-format"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepGeneralImage {
    file(relativePath: { eq: "stepGeneral.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  formGroup: {
    margin: "15px 0px",
  },
  ageField: {
    maxWidth: "60px",
    marginRight: "25px",
  },
  slider: {
    margin: "0px 20px",
    maxWidth: "100px",
  },
})

const StepGeneral = () => {
  const {
    age,
    setAge,
    gender,
    setGender,
    height,
    setHeight,
    heightUnit,
    setHeightUnit,
    weight,
    setWeight,
    weightUnit,
    setWeightUnit,
    smoking,
    setSmoking,
    exerciseLevel,
    setExerciseLevel,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography paragraph variant="h6">
        General Data
      </Typography>
      <FormGroup row className={classes.formGroup}>
        <NumberFormat
          allowLeadingZeros={false}
          format="###"
          customInput={TextField}
          size="small"
          className={classes.ageField}
          id="age"
          label="Age"
          value={age}
          onChange={event => setAge(event.target.value)}
          variant="outlined"
        ></NumberFormat>

        <RadioGroup
          style={{ flexDirection: "row" }}
          aria-label="gender"
          name="gender"
          value={gender}
          onChange={event => setGender(event.target.value)}
        >
          <FormControlLabel
            value="female"
            control={<Radio color="primary" />}
            label="Female"
          />
          <FormControlLabel
            value="male"
            control={<Radio color="primary" />}
            label="Male"
          />
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <ValueInput
          label="Height"
          value={height}
          setValue={setHeight}
          allUnits={units.height}
          unit={heightUnit}
          setUnit={setHeightUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          label="Weight"
          value={weight}
          setValue={setWeight}
          allUnits={units.weight}
          unit={weightUnit}
          setUnit={setWeightUnit}
        />
      </FormGroup>

      <FormGroup row className={classes.formGroup}>
        <Typography>Fitness Level: </Typography>
        <Slider
          className={classes.slider}
          value={exerciseLevel}
          onChange={(event, value) => setExerciseLevel(value)}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          marks
          min={1}
          max={5}
        />
        <Typography variant="caption">
          1 (Never do exercise) to 5 (Intensive Exercise everyday)
        </Typography>
      </FormGroup>

      <FormGroup className={classes.formGroup}>
        <RadioGroup
          style={{ flexDirection: "row" }}
          aria-label="smoking"
          name="smoking"
          value={smoking}
          onChange={event => {
            setSmoking(parseInt(event.target.value))
          }}
        >
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label="Not smoking"
          />
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="Smoking"
          />
        </RadioGroup>
      </FormGroup>
    </StepLayout>
  )
}

export default StepGeneral

import React, { useContext } from "react"
import ValueInput from "./valueInput"
import { HealthCheckContext } from "../../../../../context/healthCheckContext"
import { FormGroup, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { units } from "./units"
import { graphql, useStaticQuery } from "gatsby"
import StepLayout from "./stepLayout"

const queryImage = graphql`
  query stepBonesImage {
    file(relativePath: { eq: "stepBones.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({})

const StepBones = () => {
  const {
    p,
    setP,
    pUnit,
    setPUnit,
    ca,
    setCa,
    caUnit,
    setCaUnit,
    pth,
    setPth,
    pthUnit,
    setPthUnit,
  } = useContext(HealthCheckContext)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  return (
    <StepLayout imageFluid={imageFile.childImageSharp.fluid}>
      <Typography variant="h6">Bone Mineral Disorders</Typography>
      <Typography paragraph variant="body2">
        Check your recent lab results and supply serum Phosphorus, (Total)
        Calcium and PTH (Parathyroid Hormone).
      </Typography>

      <FormGroup>
        <ValueInput
          wide
          label="Phosphorus"
          value={p}
          setValue={setP}
          allUnits={units.p}
          unit={pUnit}
          setUnit={setPUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="Total Calcium"
          value={ca}
          setValue={setCa}
          allUnits={units.ca}
          unit={caUnit}
          setUnit={setCaUnit}
        />
      </FormGroup>

      <FormGroup>
        <ValueInput
          wide
          label="PTH (Intact)"
          value={pth}
          setValue={setPth}
          allUnits={units.pth}
          unit={pthUnit}
          setUnit={setPthUnit}
        />
      </FormGroup>
    </StepLayout>
  )
}

export default StepBones

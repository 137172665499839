import React, { useContext } from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { Typography, Button, Grid, Container } from "@material-ui/core"
import { HealthCheckContext } from "../../../../context/healthCheckContext"
import Image from "gatsby-image"
import Alert from "@material-ui/lab/Alert"
import { AppContext } from "../../../../context/appContext"

const queryImage = graphql`
  query healthCheckImage {
    file(relativePath: { eq: "healthAdvisor.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles({
  container: {
    marginTop: "5px",
    padding: "15px",
    textAlign: "center",
  },
  button: {
    margin: "20px",
  },
  image: {
    borderRadius: "10px",
  },
})

const HealthCheckStart = () => {
  const { setStatus, setStep } = useContext(HealthCheckContext)

  const { setAboutTabIndex } = useContext(AppContext)

  const { file: imageFile } = useStaticQuery(queryImage)

  const classes = useStyles()

  return (
    <Container>
      <Alert severity="info">
        Disclaimer: The information given via our digital health advisor is not
        intended to replace medical advice offered by physicians. View our{" "}
        <Link to="/about" onClick={() => setAboutTabIndex(0)}>
          Legal Information
        </Link>
        .
      </Alert>
      <Grid
        container
        spacing={3}
        className={classes.container}
        justify="center"
      >
        <Grid item xs={8} sm={6}>
          <Image
            fluid={imageFile.childImageSharp.fluid}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography paragraph variant="h5" color="textSecondary">
            Your digital Health Advisor!
          </Typography>

          <Typography paragraph variant="body1">
            Takes around 10 min to complete
          </Typography>
          <Typography paragraph variant="body1">
            You will need your lab results (ask your doctor for a copy)
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setStatus("ongoing")
              setStep(1)
              navigate("/healthAdvisor")
            }}
          >
            Start Health Check
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HealthCheckStart

import React from "react"
import NumberFormat from "react-number-format"
import { MenuItem, TextField, Select, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  input: {
    maxWidth: "160px",
    marginTop: "15px",
    marginBottom: "5px",
  },
  inputWide: {
    maxWidth: "300px",
    marginTop: "15px",
    marginBottom: "5px",
  },
})

const ValueInput = ({
  label,
  value,
  setValue,
  allUnits,
  unit,
  setUnit,
  wide,
  negative,
}) => {
  const classes = useStyles()

  return (
    <NumberFormat
      className={wide ? classes.inputWide : classes.input}
      customInput={TextField}
      allowNegative={negative ? true : false}
      allowLeadingZeros={false}
      decimalScale={2}
      size="small"
      variant="outlined"
      id={label}
      label={label}
      value={value}
      onChange={event => setValue(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Select
              value={unit}
              disableUnderline={true}
              onChange={event => setUnit(event.target.value)}
            >
              {allUnits.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default ValueInput

import React from "react"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import WarningIcon from "@material-ui/icons/Warning"
import ErrorIcon from "@material-ui/icons/Error"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  missing: {
    color: theme.palette.neutral.main,
  },
  ok: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const ResultIcon = ({ code }) => {
  const classes = useStyles()
  switch (code) {
    case 0:
      return <HelpOutlineIcon className={classes.missing} />
    case 1:
      return <CheckCircleIcon className={classes.ok} />
    case 2:
      return <WarningIcon className={classes.warning} />
    case 3:
      return <ErrorIcon className={classes.error} />

    default:
      return null
  }
}

export default ResultIcon
